<!-- #################################################################################### -->
<!-- ###### HERINCO                                                                ###### -->
<!-- ###### @author: Alejandro Orozco Marulanda                                    ###### -->
<!-- ###### @date: Agosto 2024                                                      ###### -->
<!-- #################################################################################### -->

<!-- #################################################################################### -->
<!-- ###### Sección de HTML                                                        ###### -->
<!-- #################################################################################### -->
<template>
  <div class="contenido">

    <!--Snackbar de elemento exitoso-->
    <v-snackbar v-model="alertValue" top :color="alertResultado ? 'success' : 'error'" timeout="2000">
      {{ alertResultado ? "Registro creado con exito" : "Error al crear el registro" }}
    </v-snackbar>

    <!--Snackbar de error-->
    <v-snackbar v-model="snackbar" top :color="alertResultado ? 'success' : 'error'" timeout="2000">
      {{ textoSnackbar }}
    </v-snackbar>
    <!-- Filtros para buscar item -->
    <section class="d-grill">
      <div class="d-flex">
        <!-- Filtrar por el Documento de un item -->
        <v-text-field type="number" :style="{ maxWidth: '30%' }" class="me-2 filtro" v-model="buscarDocumento"
          label="Documento" outlined dense hide-details color="primary">
        </v-text-field>
        <!-- Filtrar por el nombre de un item -->
        <v-text-field class="me-2 filtro" v-model="buscarNombre" label="Colaborador" outlined dense
          hide-details color="primary">
        </v-text-field>
        <!-- Filtrar por el Cargo-->
        <v-autocomplete :style="{ maxWidth: '40%' }" class="me-2 filtro" v-model="buscarCargo" label="Cargo"
          outlined dense hide-details :items="listadoCargos" :menu-props="{ offsetY: true }"
          @update:search="cargos()"  :search-input.sync="searchCargo" color="primary">
        </v-autocomplete>
        <!-- Filtrar por ubicacion-->
        <v-autocomplete :style="{ maxWidth: '30%' }" class="me-2 filtro" v-model="buscarUbicacion" label="Ubicación"
          outlined dense hide-details :items="listadoUbicaciones" :menu-props="{ offsetY: true }"
          @update:search="ubicaciones()" :search-input.sync="searchUbicacion" color="primary">
        </v-autocomplete>
        <!-- Filtrar por proceso-->
        <v-autocomplete :style="{ maxWidth: '30%' }" class="me-2 filtro" v-model="buscarProceso" label="Proceso"
          outlined dense hide-details :items="listadoProcesos" :menu-props="{ offsetY: true }"
          @update:search="procesos()" :search-input.sync="searchProceso" color="primary">
        </v-autocomplete>
        <!-- Filtrar por el Estado de contrato-->
        <v-autocomplete :style="{ maxWidth: '30%' }" class="me-2 filtro" v-model="buscarEstado" label="Estado"
          outlined dense hide-details :items="estadoContrato"
          :menu-props="{ offsetY: true }" color="primary">
        </v-autocomplete>
        <!-- Este botón abre un diálogo sincronizar contratos -->
        <div>
          <v-tooltip left color="success" :open-on-focus="false">
            <template v-slot:activator="{ on, attrs }">
              <v-btn fab small color="success" v-on="on" v-bind="attrs"
                @mousedown.prevent="!sincEnterprise.some(x => x === enterprise.code) ? dialogoCrearItem = true : dialogoSincronizar = true">
                <v-icon>{{ !sincEnterprise.some(x => x === enterprise.code) ? 'add' : 'update' }}</v-icon>
              </v-btn>
            </template>
            <span v-if="!sincEnterprise.some(x => x === enterprise.code)">Agregar</span>
            <span v-else>Sincronizar</span>
          </v-tooltip>
        </div>
      </div>
    </section>

    <!-- Diálogo para crear una Item -->
    <v-dialog v-model="dialogoCrearItem" transition="dialog-bottom-transition" max-width="45rem" persistent>
      <v-card>
        <v-card-title class="encabezado">
          <span class="text-h6"> {{ tituloDialogo }} </span>
        </v-card-title>
        <v-card-text class="pt-5">
          <!-- Formulario para registrar una Item -->
          <v-form ref="itemForm">
            <div class="d-flex">
              <!-- Campo para el Colaborador -->
              <v-autocomplete class="me-2 campo" v-model="crearItem.colaborador.idColaborador" label="Colaborador"
                dense outlined :rules="rules.required" :items="listadoColaboradores" item-value="id"
                item-text="name" :menu-props="{ offsetY: true }" @update:search="searchColaborador"
                :disabled="editar" color="primary">
              </v-autocomplete>
              <!-- Campo para el cargo -->
              <v-autocomplete class="campo" v-model="crearItem.cargo.idCargo" label="Cargo" dense
                outlined :rules="rules.required" :items="listadoCargosActivos" item-value="id" item-text="name"
                :menu-props="{ offsetY: true }" @update:search="searchCargoActivo" :disabled="editar && enterprise.code === 1" color="primary">
              </v-autocomplete>
            </div>
            <div class="d-flex">
              <!-- Campo para Proceso -->
              <v-autocomplete class="me-2 campo" v-model="crearItem.grupoProceso.proceso.idProceso" label="Proceso"
                dense outlined :rules="rules.required" :items="listadoProcesosActivos" 
                item-value="id" item-text="name" :menu-props="{ offsetY: true }" color="primary"
                @update:search="searchProcesosActivos" :disabled="editar && enterprise.code === 1">
              </v-autocomplete>
              <!-- Campo para Grupo Proceso -->
              <v-autocomplete class="campo" v-model="crearItem.grupoProceso.idGrupoProceso" label="Grupo"
                dense outlined :rules="rules.required" :items="listadoGrupos" item-value="id"
                item-text="name" :menu-props="{ offsetY: true }" @update:search="searchGrupos" color="primary"
                :disabled="editar && enterprise.code === 1">
              </v-autocomplete>
            </div>

            <div class="d-flex">
              <!-- Campo para Ubicación -->
              <v-autocomplete class="me-2 campo" v-model="crearItem.ubicacionColaborador.idUbicacionColaborador"
                label="Ubicacion" dense outlined :rules="rules.required"
                :items="listadoUbicacionesActivas" item-value="id" item-text="name" :menu-props="{ offsetY: true }"
                @update:search="searchUbicaciones" :disabled="editar && enterprise.code === 1" color="primary">
              </v-autocomplete>
              <!-- Campo para Nivel Jerárquico -->
              <v-autocomplete class="campo" v-model="crearItem.nivelJerarquico.idNivelJerarquico"
                label="Nivel Jerarquico" dense outlined :rules="rules.required"
                :items="listadoNivelJerarquico" item-value="id" item-text="name" :menu-props="{ offsetY: true }"
                @update:search="searchNivelJerarquico" :disabled="editar && enterprise.code === 1" color="primary">
              </v-autocomplete>
            </div>
            
            <div class="d-flex">
              <!-- Campo para Tipo de Contrato -->
              <v-autocomplete class="me-2 campo" v-model="crearItem.tipoContratoColaborador.idTipoContratoColaborador"
                label="Tipo contrato" dense outlined :rules="rules.required"
                :items="listadoTipoContrato" item-value="id" item-text="name" :menu-props="{ offsetY: true }"
                @update:search="searchTipoContrato" :disabled="editar && enterprise.code === 1" color="primary">
              </v-autocomplete>
              <!-- Campo para Fecha de Ingreso -->
              <v-menu ref="menuFechaIngreso" v-model="menuFechaIngreso" :close-on-content-click="false"  transition="scale-transition"
                offset-y min-width="auto">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field class="campo" v-model="crearItem.fechaIngreso" label="Fecha de ingreso" readonly v-bind="attrs" v-on="on" clearable
                    dense outlined :rules="rules.required" :disabled="editar && enterprise.code === 1"
                    @click:clear="crearItem.fechaIngreso = null">
                  </v-text-field>
                </template>
                <v-date-picker v-model="crearItem.fechaIngreso" @input="$refs.menuFechaIngreso.save(crearItem.fechaIngreso)" 
                  no-title scrollable locale="es-co">
                </v-date-picker>
              </v-menu>
            </div>
            <div class="d-flex">
              <!-- Campo para Fecha de Antiguedad -->
              <v-menu ref="menuFechaAntiguedad" v-model="menuFechaAntiguedad" :close-on-content-click="false"  transition="scale-transition"
                offset-y min-width="auto">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field class="me-2 campo" v-model="crearItem.fechaAntiguedad" label="Fecha de antiguedad" readonly v-bind="attrs" v-on="on" clearable
                    dense outlined :rules="rules.required"
                    @click:clear="crearItem.fechaAntiguedad = null">
                  </v-text-field>
                </template>
                <v-date-picker v-model="crearItem.fechaAntiguedad" @input="$refs.menuFechaAntiguedad.save(crearItem.fechaAntiguedad)" 
                  no-title scrollable locale="es-co" :max="hoy">
                </v-date-picker>
              </v-menu>
              <!-- Campo para Fecha de retiro -->
              <v-menu ref="menuFechaRetiro" v-model="menuFechaRetiro" :close-on-content-click="false"  transition="scale-transition"
                offset-y min-width="auto">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field class="campo" v-model="crearItem.fechaRetiro" label="Fecha de retiro" readonly v-bind="attrs" v-on="on" clearable
                    dense outlined :disabled="editar && enterprise.code === 1"
                    @click:clear="crearItem.fechaRetiro = null">
                  </v-text-field>
                </template>
                <v-date-picker v-model="crearItem.fechaRetiro" @input="$refs.menuFechaRetiro.save(crearItem.fechaRetiro)" 
                  no-title scrollable locale="es-co">
                </v-date-picker>
              </v-menu>
            </div>

          </v-form>
          <!-- Botones del formulario -->
          <div class="d-flex justify-end">
            <v-btn class="me-2" text color="error" @click="clear()">Cerrar</v-btn>
            <v-btn :disabled="!formValid" depressed :color="!formValid ? 'grey' : 'success'"
              @click="!editar ? agregarItem() : editarItem()">Guardar
            </v-btn>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Tabla de items -->
    <v-data-table class="elevation mt-4" :items="tabla.items" :loading="tabla.loading" :page="tabla.page"
    fixed-header :headers="tabla.headers" :items-per-page="tabla.itemsPerPage" :footer-props="tabla.footerProps"
    @update:items-per-page="(i) => tabla.itemsPerPage = i" @update:page="(p) => tabla.page = p"
    :server-items-length="tabla.totalPage" height="64vh" @mouseenter="isMenuOpen = false">

      <template v-slot:[`item.colaborador`]="{ item }">
        {{ item.colaborador.nombre }} {{ item.colaborador.apellidos }}
      </template>

      <template v-slot:[`item.estado`]="{ item }">
        <v-icon :color="item.eliminado === false ? 'success' : 'error'">
          {{ item.eliminado === false ? 'check_circle' : 'cancel' }}
        </v-icon>
        {{ item.eliminado === false ? 'Activo' : 'Inactivo' }}
      </template>

      <template v-slot:[`item.fechaIngreso`]="{ item }">
        {{ formatoFecha(item.fechaIngreso) }}
      </template>

      <template v-slot:[`item.acciones`]="{ item }">
        <v-tooltip bottom :color="!item.eliminado ? 'orange' : 'grey'">
          <template v-slot:activator="{ on, attrs }">
            <v-btn small icon fab v-on="on" v-bind="attrs" :color="!item.eliminado ? 'orange' : 'grey'">
              <v-icon @click="item.eliminado === false && abrirDialogoEditar(item)">border_color</v-icon>
            </v-btn>
          </template>
          <span>{{ !item.eliminado ? 'Editar' : 'Contrato inactivo' }}</span>
        </v-tooltip>

        <v-tooltip v-if="!sincEnterprise.some(x => x === enterprise.code)" bottom
          :color="item.eliminado === false ? 'error' : 'success'">
          <template v-slot:activator="{ on , attrs }">
            <v-btn icon fab small v-on="on" v-bind="attrs">
              <v-icon @click="abrirDialogoEstado(item)" :color="item.eliminado === false ? 'error' : 'success'">
                {{ item.eliminado === false ? 'person_add_disabled' : 'how_to_reg'}}
              </v-icon>
            </v-btn>
          </template>
          <span>{{ item.eliminado === false ? 'Inactivar' : 'Activar' }}</span>
        </v-tooltip>
      </template>

      <!-- Columna de los detalles -->
      <template v-slot:[`item.detalles`]="{ item }">
        <v-tooltip bottom color="blue">
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-on="on" v-bind="attrs" fab icon small color="blue">
              <v-icon @click="abrirDialogoDetalles(item)">library_add</v-icon>
            </v-btn>
          </template>
          <span>Ver detalles</span>
        </v-tooltip>
      </template>
      <template v-slot:[`footer.page-text`]="items">
        {{ items.pageStart }} - {{ items.pageStop }} de {{ items.itemsLength }}
      </template>
    </v-data-table>

    <!-- dialogo para cambiar el estado -->
    <v-dialog v-model="dialogoCambiarEstado" transition="dialog-bottom-transition" max-width="23rem" persistent>
      <v-card>
        <v-card-title class="encabezado">
          <span class="text-h6"> {{ estado }} </span>
        </v-card-title>
        <v-card-text class="pt-5">
          <div class="d-flex justify-end">
            <v-btn class="me-2" color="error" text @click="dialogoCambiarEstado = false">No</v-btn>
            <v-btn color="success" depressed @click="editarItem()">Si</v-btn>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- dialogo para realizar la sincronizacion -->
    <v-dialog v-model="dialogoSincronizar" transition="dialog-bottom-transition" max-width="25rem" persistent>
      <v-card>
        <v-card-title class="encabezado">
          <span class="text-h6"> ¿Desea sincronizar la información? </span>
        </v-card-title>
        <v-card-text class="pt-5">
          <div>
            <p v-if="cargando" class="text-center ma-0">
              Por favor espere un momento...
              <v-icon :class="{ 'rotate-animation': cargando }"
                large>rotate_right
              </v-icon>
            </p>
          </div>
          <div class="d-flex justify-end">
            <v-btn v-if="!cargando" class="me-2" color="error" text @click="dialogoSincronizar = false">No</v-btn>
            <v-btn v-if="!cargando" color="success" depressed @click="sincronizar()">Si</v-btn>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Dialogo para ver los detalles del contrato seleccionado -->
    <v-dialog v-model="dialogoDetalles" transition="dialog-bottom-transition" max-width="25rem" persistent>
      <v-card>
        <v-card-title class="encabezado">
          <span class="text-h6">Detalles</span>
        </v-card-title>
        <v-card-text class="pt-6 detalles">
          <div v-if="contratoSeleccionado.fechaAntiguedad !== null">
            <span class="font-weight-black">Fecha antiguedad: </span>
            {{ formatoFecha(contratoSeleccionado.fechaAntiguedad) }}
          </div>
          <div v-if="contratoSeleccionado.fechaRetiro !== null">
            <span class="font-weight-black">Fecha retiro: </span>
            {{ formatoFecha(contratoSeleccionado.fechaRetiro) }}
          </div>
          <div v-if="contratoSeleccionado.grupoProceso">
            <span class="font-weight-black">Grupo proceso: </span>
            {{ contratoSeleccionado.grupoProceso.nombre }}
          </div>
          <div v-if="contratoSeleccionado.nivelJerarquico">
            <span class="font-weight-black">Nivel jerarquico: </span>
            {{ contratoSeleccionado.nivelJerarquico.nombre }}
          </div>
          <div v-if="contratoSeleccionado.tipoContratoColaborador">
            <span class="font-weight-black">Tipo contrato: </span>
            {{ contratoSeleccionado.tipoContratoColaborador.nombre }}
          </div>
          <div class="d-flex justify-end">
            <v-btn color="error" text @click="dialogoDetalles = false">Cerrar</v-btn>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<!-- #################################################################################### -->
<!-- ###### Sección de Script                                                      ###### -->
<!-- #################################################################################### -->
<script>
import { mapMutations, mapState } from "vuex";
import { Role } from "../../../../router/role";
import moment from 'moment';

export default {
  name: "tipos-contratos",
  props: {
  },
  data() {
    return {
      ruta: "msa-hr/api/contrato",
      nombreItem: "Contrato",
      nombreId: "idContrato",
      hoy: new Date().toISOString().slice(0, 10), // Obtiene la fecha actual en formato YYYY-MM-DD
      buscarNombre: "",
      buscarDocumento: "",
      buscarCargo: null,
      buscarUbicacion: null,
      buscarProceso: null,
      buscarEstado: null,
      dialogoCrearItem: false,
      searchCargo: "",
      searchCargoActivo: "",
      searchUbicacion: "",
      searchProceso: "",
      searchColaborador: "",
      searchTipoContrato: "",
      searchProcesosActivos: "",
      searchGrupos: "",
      searchUbicaciones: "",
      searchNivelJerarquico: "",
      menuFechaIngreso: false,
      menuFechaAntiguedad: false,
      menuFechaRetiro: false,
      fechaIngreso: null,
      fechaAntiguedad: null,
      fechaRetiro: null,
      tempFechaIngreso: null,
      tempFechaAntiguedad: null,
      tempFechaRetiro: null,
      formattedDate: null,
      crearItem: {
        colaborador: {
          idColaborador: null
        },
        cargo: {
          idCargo: null
        },
        grupoProceso: {
          idGrupoProceso: null,
          proceso: {
            idProceso: null
          }
        },
        ubicacionColaborador: {
          idUbicacionColaborador: null
        },
        nivelJerarquico: {
          idNivelJerarquico: null
        },
        tipoContratoColaborador: {
          idTipoContratoColaborador: null
        },
        fechaIngreso: "",
        fechaAntiguedad: "",
        fechaRetiro: "",
        eliminado: false,
        codStone: "",
      },
      lastmodifiedby: "",
      tabla: {
        loading: false,
        items: [],
        headers: [
          { text: "DOCUMENTO", width: "8%", sortable: false, align: "left", value: 'colaborador.numeroDocumento' },
          { text: "COLABORADOR", width: "17%", sortable: false, align: "left", value: 'colaborador' },
          { text: "CARGO", width: "17%", sortable: false, align: "left", value: "cargo.nombre" },
          { text: "UBICACIÓN", width: "13%", sortable: false, align: "left", value: 'ubicacionColaborador.nombre' },
          { text: "PROCESO", width: "13%", sortable: false, align: "left", value: 'grupoProceso.proceso.nombre' },
          { text: "FECHA INGRESO", width: "8%", sortable: false, align: "center", value: "fechaIngreso" },
          { text: "ESTADO", width: "8%", sortable: false, align: "center", value: "estado" },
          { text: "ACCIONES", width: "10%", sortable: false, align: "center", value: "acciones" },
          { text: "DETALLES", width: "8%", sortable: false, align: "center", value: "detalles" },
        ],
        footerProps: {
          'items-per-page-options': [10, 20, 30, 40],
          'items-per-page-text': 'Items por página:',
          'show-current-page': true,
          'show-first-last-page': true
        },
        itemsPerPage: 10,
        page: 1,
        totalPage: 0,
      },
      tituloDialogo: "Agregar",
      userRoles: {},
      roles: Role,
      rules: {
        required: [(v) => !!v || "Este campo es requerido."],
      },
      dialogoCambiarEstado: false,
      editar: false,
      alertValue: false,
      alertResultado: false,
      sincEnterprise: [1],
      estadoContrato: [
        { value: false, text: "ACTIVO" },
        { value: true, text: "INACTIVO" },
      ],
      listadoColaboradores: [],
      listadoCargos: [],
      listadoCargosActivos: [],
      listadoTipoContrato: [],
      listadoProcesos: [],
      listadoProcesosActivos: [],
      listadoGrupos: [],
      listadoUbicaciones: [],
      listadoUbicacionesActivas: [],
      listadoNivelJerarquico: [],
      dialogoDetalles: false,
      contratoSeleccionado: {},
      dialogoSincronizar: false,
      cargando: false,
      snackbar: false,
      textoSnackbar: '',
    };
  },
  created() {
    this.userRoles = this.auth.roles;
  },
  mounted() {
    this.listarItems();
    this.colaboradores();
    this.cargosActivos();
    this.tiposContratosActivos();
    this.procesosActivos();
    this.gruposActivos();
    this.ubicacionesActivas();
    this.nivelesJerarquicos();
    this.lastmodifiedby = this.auth.username.toUpperCase();
    this.usuario = this.auth.username.toUpperCase();
  },
  computed: {
    ...mapState(["auth", "notify", "busy", "enterprise"]),
    formValid() {
      const { colaborador, cargo, grupoProceso, ubicacionColaborador, nivelJerarquico, tipoContratoColaborador,
        fechaIngreso, fechaAntiguedad } = this.crearItem;
      return colaborador.idColaborador !== null && cargo.idCargo !== null && grupoProceso.idGrupoProceso !== null && 
      grupoProceso.proceso.idProceso !== null && ubicacionColaborador.idUbicacionColaborador !== null &&
      nivelJerarquico.idNivelJerarquico !== null && tipoContratoColaborador.idTipoContratoColaborador !== null &&
      fechaIngreso && fechaAntiguedad;
    },
    estado() {
      return !this.crearItem.eliminado
        ? `¿Desea activar ${this.nombreItem}?`
        : `¿Desea inactivar ${this.nombreItem}?`;
    },
    /**
     * Función computada que devuelve la fecha actual en formato ISO (YYYY-MM-DD)
     * para que en el v-date-picker no seleccione fechas posteriores a las del día actual. 
     */
     currentDate() {
      return new Date().toISOString().split('T')[0];
    },
  },
  watch: {
    // Escucha los cambios en la página actual de la tabla de items.
    // Si cambia, llama a la función para listar las items.
    "tabla.page": function () {
      this.listarItems();
    },
    // Escucha cambios en la cantidad de ítems por página en la tabla de items.
    // Si cambia, reinicia la página actual a 1 y lista los items.
    "tabla.itemsPerPage": function () {
      this.tabla.page = 1;
      this.listarItems();
    },
    // Escucha cambios en los filtros de búsqueda.
    // Si el criterio de búsqueda cambia, reinicia la página actual a 1.
    buscarNombre: function () {
      this.tabla.page = 1;
      this.listarItems();
    },
    buscarDocumento: function () {
      this.tabla.page = 1;
      this.listarItems();
    },
    buscarCargo: function () {
      this.buscarCargo = this.buscarCargo ? this.buscarCargo : null;
      this.tabla.page = 1;
      this.listarItems();
    },
    buscarUbicacion: function () {
      this.buscarUbicacion = this.buscarUbicacion ? this.buscarUbicacion : null;
      this.tabla.page = 1;
      this.listarItems();
    },
    buscarProceso: function () {
      this.buscarProceso = this.buscarProceso ? this.buscarProceso : null;
      this.tabla.page = 1;
      this.listarItems();
    },
    "crearItem.fechaIngreso": function (newVal) {
      // Formatea la fecha si es necesario
      this.formattedDate = this.formatDate(newVal);
    },
    buscarEstado: function () {
      this.tabla.page = 1;
      this.listarItems();
    },
    searchColaborador: function () {
      this.colaboradores(this.searchColaborador);
    },
    searchCargoActivo: function () {
      this.cargosActivos(this.searchCargoActivo);
    },
    searchTipoContrato: function () {
      this.tiposContratosActivos(this.searchTipoContrato);
    },
    searchProcesosActivos: function () {
      this.procesosActivos(this.searchProcesosActivos);
    },
    searchGrupos: function () {
      this.gruposActivos(this.searchGrupos);
    },
    searchUbicaciones: function () {
      this.ubicacionesActivas(this.searchUbicaciones);
    },
    searchNivelJerarquico: function () {
      this.nivelesJerarquicos(this.searchNivelJerarquico);
    },
    searchCargo: function () {
      this.cargos(this.searchCargo);
    },
    searchProceso: function () {
      this.procesos(this.searchProceso);
    },
    searchUbicacion: function () {
      this.ubicaciones(this.searchUbicacion);
    },
  },
  methods: {
    ...mapMutations([
      "updateAuth",
      "hideNotify",
      "showSuccess",
      "showBusy",
      "hideBusy",
    ]),
    /**
     * Método que obtiene y lista los items de la base de datos utilizando criterios de paginación y filtrado.
     * La respuesta obtenida se utiliza para actualizar la tabla de items en el frontend,
     * asignando la lista de items a 'tabla.items' y la cantidad total de items a 'tabla.totalPage'.
     */
    listarItems() {
      this.tabla.loading = true;
      this.$http
        .get(`${this.ruta}/listar`, {
          params: {
            page: this.tabla.page - 1,
            size: this.tabla.itemsPerPage,
            numeroDocumento: `${this.buscarDocumento}`,
            nombreCompleto: `${this.buscarNombre.toLowerCase()}`,
            nombreCargo: `${this.removeNull(this.buscarCargo)}`,
            nombreUbicacion: `${this.removeNull(this.buscarUbicacion)}`,
            nombreProceso: `${this.removeNull(this.buscarProceso)}`,
            idEmpresa: this.enterprise.code,
            eliminado: this.buscarEstado
          },
        })
        .then((response) => {
          this.tabla.items = response.data.content;
          this.tabla.totalPage = response.data.totalElements;
          this.tabla.loading = false;
        })
        .catch((error) => {
          this.handleError(error, 'Error al listar los contratos');
          this.tabla.loading = false;
        });
    },
    //Función para editar un Ítem
    async editarItem() {
      if (this.editar) {
        const item = {
          [this.nombreId]: this.crearItem[this.nombreId],
          idEmpresa: this.enterprise.code,
          idColaborador: this.crearItem.colaborador.idColaborador,
          idCargo: this.crearItem.cargo.idCargo,
          idTipoContratoColaborador: this.crearItem.tipoContratoColaborador.idTipoContratoColaborador,
          idGrupoProceso: this.crearItem.grupoProceso.idGrupoProceso,
          idUbicacionColaborador: this.crearItem.ubicacionColaborador.idUbicacionColaborador,
          idNivelJerarquico: this.crearItem.nivelJerarquico.idNivelJerarquico,
          fechaIngreso: this.crearItem.fechaIngreso ? `${this.crearItem.fechaIngreso}T00:00:00-05:00` : null,
          fechaAntiguedad: this.crearItem.fechaAntiguedad ? `${this.crearItem.fechaAntiguedad}T00:00:00-05:00` : null,
          eliminado: this.crearItem.eliminado,
          lastmodifiedby: this.usuario,
          createdby:this.usuario,
          codStone: this.crearItem.codStone,
          fechaRetiro: this.crearItem.fechaRetiro ? `${this.crearItem.fechaRetiro}T00:00:00-05:00` : null
        }

        await this.$http.post(`${this.ruta}/guardar`, item)
          .then(() => {
            this.listarItems();
            this.dialogoCrearItem = false;
            this.clear();
          }).catch((error) => {
            this.handleError(error, 'Error al editar el registro');
          });
      }
    },

    //Función para agregar un Item
    async agregarItem() {

      var codigo;

      try {
        codigo = await this.generarCodigoStone()
        if (typeof codigo != "number" && codigo < 1) {
          throw new Error("No se puedo generar codStone")
        }
      } catch (error) {
        console.error(error);
      }

      const item = {
        idEmpresa: this.enterprise.code,
        idColaborador: this.crearItem.colaborador.idColaborador,
        idCargo: this.crearItem.cargo.idCargo,
        idTipoContratoColaborador: this.crearItem.tipoContratoColaborador.idTipoContratoColaborador,
        idGrupoProceso: this.crearItem.grupoProceso.idGrupoProceso,
        idUbicacionColaborador: this.crearItem.ubicacionColaborador.idUbicacionColaborador,
        idNivelJerarquico: this.crearItem.nivelJerarquico.idNivelJerarquico,
        fechaIngreso: this.crearItem.fechaIngreso,
        fechaAntiguedad: this.crearItem.fechaAntiguedad,
        codStone: codigo,
        lastmodifiedby: this.usuario,
        createdby:this.usuario,
        fechaRetiro: this.crearItem.fechaRetiro,
      };
      
      this.$http
        .post(`${this.ruta}/guardar`, item)
        .then(() => {
          this.listarItems();
          this.dialogoCrearItem = false;
          this.clear();
          this.alertResultado = true;
          this.alertValue = true;
        })
        .catch((error) => {
          this.handleError(error, 'Error al crear un registro');
        });
    },
    //Manejo de solicitudes al BackEnd
    async listar(nombre, Url, soloActivos, proceso) {
      let lista = await this.$http.get(`msa-hr/api/${Url}/listar`, {
        params: {
          idEmpresa: this.enterprise.code,
          nombre,
          nombreCompleto: nombre,
          eliminado: soloActivos ? !soloActivos : '',
          proceso,
          size: 100
        },
      });
      return lista.data.content ? lista.data.content : [];
    },
    //Llenado de listas Colaboradores activos con manejo de ID
    async colaboradores(nombreColaborador) {
      const lista = await this.listar(nombreColaborador, 'colaborador')
      this.listadoColaboradores = lista.map(item => ({
        name: item.nombre.trim() + " " + item.apellidos.trim(),
        id: item.idColaborador,
        numeroDocumento: item.numeroDocumento
      }))
    },
    //Llenado de lista Cargos
    async cargos(nombreCargo) {
      const lista = await this.listar(nombreCargo, 'cargo')
      this.listadoCargos = lista.map(item => (
        item.nombre.trim()
      ))
    },
    //Llenado de listas Cargos activos con manejo de ID
    async cargosActivos(nombreCargo) {
      const lista = await this.listar(nombreCargo, 'cargo',true)

      this.listadoCargosActivos = lista.map(item => ({
        name: item.nombre.trim(),
        id: item.idCargo
      }))
    },
    //Llenado de listas Tipo Contratos activos con manejo de ID
    async tiposContratosActivos(nombreTipoContrato) {
      const lista = await this.listar(nombreTipoContrato, 'tipo-contrato', false)
      this.listadoTipoContrato = lista.map(item => ({
        name: item.nombre.trim(),
        id: item.idTipoContratoColaborador
      }))
    },
    //Llenado de lista Procesos
    async procesos(nombreProceso) {
      const lista = await this.listar(nombreProceso, 'proceso')
      this.listadoProcesos = lista.map(item => (
        item.nombre.trim()
      ))
    },
    //Llenado de listas Procesos activos con manejo de ID
    async procesosActivos(nombreProceso) {
      const lista = await this.listar(nombreProceso, 'proceso', false)
      this.listadoProcesosActivos = lista.map(item => ({
        name: item.nombre.trim(),
        id: item.idProceso
      }))
    },
    //Llenado de listas Procesos activos con manejo de ID
    async gruposActivos(nombreGrupo, nombreProceso) {
      const lista = await this.listar(nombreGrupo, 'grupo-proceso', false, nombreProceso)
      this.listadoGrupos = lista.map(item => ({
        name: item.nombre.trim(),
        id: item.idGrupoProceso
      }))
    },
    //Manejo de Ubicaciones
    async ubicaciones(nombreUbicacion) {
      const lista = await this.listar(nombreUbicacion, 'ubicacion')
      this.listadoUbicaciones = lista.map(item => (
        item.nombre.trim()
      ))
    },
    //Llenado de listas Ubicaciones activas con manejo de ID
    async ubicacionesActivas(nombreUbicacion) {
      const lista = await this.listar(nombreUbicacion, 'ubicacion', false)
      this.listadoUbicacionesActivas = lista.map(item => ({
        name: item.nombre.trim(),
        id: item.idUbicacionColaborador
      }))
    },
    //Llenado de listas Niveles gerárquicos activos con manejo de ID
    async nivelesJerarquicos(nombreNivelJerarquico) {
      const lista = await this.listar(nombreNivelJerarquico, 'nivel-jerarquico', false)
      this.listadoNivelJerarquico = lista.map(item => ({
        name: item.nombre.trim(),
        id: item.idNivelJerarquico
      }))
    },
    //Generar Código Stone
    async generarCodigoStone() {
      return await this.$http
        .get(`${this.ruta}/max-cod-stone`, {
          params: {
            idEmpresa: this.enterprise.code,
          },
        })
        .then((response) => {
          return response.data > 0 ? response.data + 1 : 1;
        })
        .catch((error) => {
          this.handleError(error, 'Error al generar el código stone');
        });
    },
    formatoFecha(fecha){
      return moment(fecha).format('YYYY-MM-DD')
    },
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split('-');
      return `${year}-${month}-${day}`;
    },
    /**
     * Método que formatea la fecha seleccionada en el formulario.
     * @param date - fecha a formatear
     */
     formatDateToYMD(date) {
      if (!date) return '';
      const d = new Date(date);
      return d.toISOString().split('T')[0];
    },
    /**
     * Método que guarda la fecha de retiro.
     */
     saveFechaRetiro() {
      this.menuFechaRetiro = false;
      this.crearItem.fechaRetiro = this.formatDateToYMD(this.tempFechaRetiro);
      this.fechaRetiro = this.crearItem.fechaRetiro;
    },
    /**
     * Método que limpia el campo de entrada de la fecha de nacimiento.
     */
     cleanFechaRetiro() {
      this.menuFechaRetiro = false;
      this.crearItem.fechaRetiro = null;
      this.fechaRetiro = null;
      this.tempFechaRetiro = null;
    },
    async abrirDialogoEditar(item) {

      this.tituloDialogo = "Editar";
      this.editar = true;
      this.crearItem.colaborador.idColaborador = item.colaborador.idColaborador;
      this.listadoCargosActivos = [{id:item.cargo.idCargo,name:item.cargo.nombre.trim()}]
      this.crearItem.cargo.idCargo = item.cargo.idCargo;
      this.crearItem.grupoProceso.proceso.idProceso = item.grupoProceso.proceso.idProceso;
      this.listadoProcesosActivos = [{id:item.grupoProceso.proceso.idProceso, name:item.grupoProceso.proceso.nombre}]
      this.crearItem.grupoProceso.idGrupoProceso = item.grupoProceso.idGrupoProceso;
      this.crearItem.ubicacionColaborador.idUbicacionColaborador = item.ubicacionColaborador.idUbicacionColaborador;
      this.listadoUbicacionesActivas = [{id:item.ubicacionColaborador.idUbicacionColaborador, name:item.ubicacionColaborador.nombre}]
      this.crearItem.nivelJerarquico.idNivelJerarquico = item.nivelJerarquico.idNivelJerarquico;
      this.crearItem.tipoContratoColaborador.idTipoContratoColaborador = item.tipoContratoColaborador.idTipoContratoColaborador;
      this.crearItem.fechaIngreso = this.formatDateToYMD(item.fechaIngreso);
      this.crearItem.fechaAntiguedad = this.formatDateToYMD(item.fechaAntiguedad);
      this.crearItem.fechaRetiro = this.formatDateToYMD(item.fechaRetiro);
      this.crearItem.codStone = item.codStone;
      this.contratoSeleccionado = item;

      this.dialogoCrearItem = true;
    },
    abrirDialogoEstado(item) {
      this.crearItem = {
        ...item,
        eliminado: item.eliminado ? false : true,
      };
      this.dialogoCambiarEstado = true;
    },
    abrirDialogoDetalles(item) {
      this.contratoSeleccionado = item;
      this.dialogoDetalles = true;
    },
    sincronizar() {
      this.cargando = true;
      this.$http.post(`${this.ruta}/sincronizar`, null, {
        params: {
          idEmpresa: this.enterprise.code,
          soloActivos: false
        }
      }).then((response) => {
        this.textoSnackbar=`Fueron encontrados ${response.data} registros para actualizar`;
        this.alertResultado = true;
        this.snackbar=true;
        this.tabla.page = 1;
        this.listarItems();
        this.cargando = false;
        this.dialogoSincronizar = false;
      }).catch((error)=> {
        this.textoSnackbar=error.message;
        this.alertResultado = false;
        this.snackbar=true;
        this.handleError(error, 'Error al sincronizar los datos');
        this.cargando = false;
      })
    },
    removeNull(val){
      return val ? val : '';
    },
    /**
     * Método para mostrar las alertas cuando genera error el back.
     * @param error - El error generado.
     * @param mensaje - El mensaje de error.
     */
    handleError(error, mensaje) {
      console.error(error);
      this.snackbar = true;
      this.textoSnackbar = `${mensaje}: ${error.response?.data?.message || error.message || error}`;
    },
    /**
     * Método que se encarga de limpiar o restablecer varios valores a su estado inicial.
     */
    clear() {
      // this.soloVista = false;
      this.dialogoCrearItem = false;
      this.editar = false;
      // this.crearItem.colaborador.idColaborador = null;
      // this.crearItem.cargo.idCargo = null;
      // this.crearItem.tipoContratoColaborador.idTipoContratoColaborador = null;
      // this.crearItem.grupoProceso.proceso.idProceso = null;
      // this.crearItem.grupoProceso.idGrupoProceso = null;
      // this.crearItem.ubicacionColaborador.idUbicacionColaborador = null;
      // this.crearItem.nivelJerarquico.idNivelJerarquico = null;
      // this.crearItem.codStone = null;
      this.botonDisabled = false;
      this.tituloDialogo = "Agregar";
      this.dialogoCambiarEstado = false;
      if (this.$refs.itemForm) {
        this.$refs.itemForm.reset();
      }
      this.cargando = false;
    },
  }
};
</script>
<!-- #################################################################################### -->
<!-- ###### Sección de Style                                                       ###### -->
<!-- #################################################################################### -->
<style scoped>
.contenido {
  padding: 1rem;
  height: 86vh;
}

.encabezado {
  background-color: #1867c0;
  color: white;
}

::v-deep .elevation div table thead tr th {
  background-color: rgb(223, 223, 223) !important;
}

::v-deep .elevation div table thead tr th span {
  font-weight: bold;
  color: black !important;
}

.campo {
  width: 50%;
}

.filtro {
  width: 25%;
}

.detalles div , .detalles span {
  color: black;
}

.rotate-animation {
    animation: rotate 2s linear infinite;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
</style>